import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { MixTheme } from "../../styles";
import { ExternalTrainingType } from "src/interfaces/ExternalTrainings";

interface GridColDef {
  field: string;
  headerName: string;
}
const Columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "delete",
    headerName: "Delete",
  },
];

interface TrainingListProps {
  trainings: ExternalTrainingType[];
  onClickDelete: (item: ExternalTrainingType) => void;
}

const TrainingsList = ({ trainings = [],onClickDelete }: TrainingListProps) => {
  const theme = useTheme<MixTheme>();

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        marginTop: "32px",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {trainings.map((item: ExternalTrainingType) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography color={theme.palette.text?.secondary}>
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => onClickDelete(item)}
                  >
                    <DeleteIcon />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TrainingsList;
