import { UserManagementTypes } from "./types";

const initialState = {
  stores: [],
  managers: [],
};
export const userManagementReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case UserManagementTypes.GET_ALL_USER_TYPES + "_SUCCESS":
        return {
            ...state,
            stores:action.payload?.data?.stores,
            managers:action.payload?.data?.managers,
        }
    default : return{
        ...state
    }
  }
}