import axios from 'axios';
import qs from 'qs';
import Storage from '../storage';

// `data` is the data to be sent as the request body
// Only applicable for request methods 'PUT', 'POST', and 'PATCH'
export async function getHttpClient(
  path,
  method,
  data,
  params,
  headers,
) {
  const query =
    params !== null ? '?' + qs.stringify(params, {allowDots: true}) : '';
  const client = axios.create({
    baseURL: path + query,
    // url: path + query,
    method: method,
    timeout: 60 * 1000,
    headers: headers || {},
  });

  // //console.log("query",query);

  client.interceptors.request.use(
    function (config) {
      // ToDO : uncomment while integrating auth
      const token = Storage.token.getValue();
      if (token) {
        config.headers.Authorization = token;
      }

      if (headers !== null && headers !== undefined) {
        // //console.log('headers', config.headers);
        config.headers = {...config.headers, ...headers};
      }
      // //console.log('****Config', config)
      config.data = data;
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      // //console.log("ERROR",error);
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    async function (response) {
      // //console.log({response});
      return response;
    },
    async function (error) {
      // eslint-disable-next-line no-shadow
      let data = error.response || {};
      if (data.hasOwnProperty('data')) {
        data = data.data;
      }
      // //console.log('interceptors error', data);
      // if (Object.keys(data).length === 0) {
      //   //console.log('raw error', error);
      // }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(data);
    },
  );
  return client.request();
}
