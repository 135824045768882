import { DropdownOptions } from "../../components/Dropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserTypes } from "src/redux/userManagement/actions";
import { GalleryFilterData } from "../../layouts/gallery/GalleryFilters";
import moment from "moment";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
import Accidentlayout from "../../layouts/accident";
import { Accidents } from "src/interfaces/Accidents";

const Accident = () => {
  const [dataSelector, setDataSelector] = useState<GalleryFilterData>({
    from_date: moment().subtract("days",7).format("YYYY-MM-DD").toString(),
    to_date: moment().format("YYYY-MM-DD").toString(),
  });

  const [accidents, setAccidents] = useState<Accidents[]>([]);

  const stores: any[] = useSelector(
    (state: any) => state.userManagement.stores
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserTypes());
  }, [dispatch]);

  const getImages = useCallback(async () => {
    try {
      const data = await getHttpClient(HTTPService.GET_ACCIDENT, "get",null, {
        from_date: dataSelector.from_date,
        to_date: dataSelector.to_date,
        store_id: dataSelector.store_id,
      });
      setAccidents(data.data.data);
    } catch (e) {}
  }, [dataSelector]);

  useEffect(() => {
    if (dataSelector.store_id) getImages();
  }, [dataSelector, getImages]);

  const storesList = useMemo<DropdownOptions[]>(() => {
    return stores.map((item: any) => {
      return {
        value: item?.user_uuid,
        label: `${item.username} - ${item.shop_id}`,
      } as DropdownOptions;
    });
  }, [stores]);

  const onChangeDropdownValue = useCallback((key: string, val: string) => {
    setDataSelector((data) => {
      return {
        ...data,
        [key]: val,
      };
    });
  }, []);

  useEffect(() => {
    if (storesList?.length > 0) {
      onChangeDropdownValue("store_id", storesList[0].value);
    }
  }, [storesList,onChangeDropdownValue]);

  return (
    <Accidentlayout
      storesList={storesList}
      dataSelector={dataSelector}
      onChangeDropdownValue={onChangeDropdownValue}
      accidents={accidents}
    />
  );
};
export default Accident;
