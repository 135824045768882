import {HTTPService} from '../../services/httpservice';
import {UserManagementTypes} from './types';

export const getAllUserTypes = () => {
  return {
    type: UserManagementTypes.GET_ALL_USER_TYPES,
    request: {
      path: HTTPService.GET_ALL_USER_TYPES,
      method: 'GET',
    },
  };
};
export const createAreaManager = (data:any) => {
  return {
    type: UserManagementTypes.GET_ALL_USER_TYPES,
    request: {
      path: HTTPService.CREATE_AREA_MANAGER,
      method: 'POST',
      data
    },
  };
};
export const updateAreaManager = (data:any) => {
  return {
    type: UserManagementTypes.GET_ALL_USER_TYPES,
    request: {
      path: HTTPService.UPDATE_AREA_MANAGER,
      method: 'POST',
      data
    },
  };
};
export const deleteAreaManager = (data:any) => {
  return {
    type: UserManagementTypes.GET_ALL_USER_TYPES,
    request: {
      path: HTTPService.DELETE_AREA_MANAGER,
      method: 'DELETE',
      data
    },
  };
};