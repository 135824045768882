import React, { useEffect, useState, useCallback } from "react";
import PdfPage from "./pdfpage";
import { useParams } from "react-router-dom";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
// import ShowSearchPopoverButtonExample from "./SearchPopup";

const PdfContainer = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);

  const checkUser = useCallback(async () => {
    try {
      await getHttpClient(
        HTTPService.LIBRARY,
        "post",
        null,
        null,
        {
          'Authorization': params?.token,
        }
      );
      setLoading(false);
      setShow(true);
    } catch (e) {
      setLoading(false);
      setShow(true);
    }
  }, [params]);

  useEffect(() => {
    if (params?.token) {
      checkUser();
    }
  }, [params,checkUser]);

  return <PdfPage show={show} isLoading={loading}/>;
};
export default PdfContainer;
