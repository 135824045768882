import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { MixTheme } from "../../styles";
import React from "react";
import { useSelector } from "react-redux";
import PieChartIcon from "@mui/icons-material/PieChart";

interface GridColDef {
  field: string;
  headerName: string;
}

const Columns: GridColDef[] = [
  {
    field: "username",
    headerName: "Username",
  },
  {
    field: "email",
    headerName: "Email",
  },
  {
    field: "shop_id",
    headerName: "Shop ID",
  },
  {
    field: "shop_number",
    headerName: "Shop Number",
  },
  {
    field: "shop_region",
    headerName: "Shop Region",
  },
];
interface StoreListProps {
  select?: boolean;

  selectedStores?: string[];
  onPressSelect?: (id: string, value: boolean) => void;
  onClickChart?: (item: any) => void;
}
const StoresListLayout = ({
  select,
  selectedStores,
  onPressSelect,
  onClickChart,
}: StoreListProps) => {
  const stores = useSelector((state: any) => state.userManagement.stores);
  const theme = useTheme<MixTheme>();

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {select && <TableCell></TableCell>}
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
              {onClickChart && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {stores.map((item: any) => {
              return (
                <TableRow>
                  {select && (
                    <TableCell width={"30px"}>
                      <Checkbox
                        checked={selectedStores?.includes(item?.user_uuid)}
                        onChange={(_, checked) =>
                          onPressSelect?.(item?.user_uuid, checked)
                        }
                      />
                    </TableCell>
                  )}
                  {Columns.map((column) => {
                    return (
                      <TableCell key={item.headerName}>
                        <Typography color={theme.palette.text?.secondary}>
                          {item[column.field]}
                        </Typography>
                      </TableCell>
                    );
                  })}
                  {onClickChart && (
                    <TableCell
                      onClick={() => onClickChart(item)}
                      sx={{ width: "30px" }}
                    >
                      <PieChartIcon />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StoresListLayout;
