class SecureStorage {
  key: string;
  constructor(key: string) {
    this.key = key;
  }
  setValue = (value: any) => {
    localStorage.setItem(this.key, value);
  };
  getValue = () => {
    return localStorage.getItem(this.key);
  };
  clear = () => {
    localStorage.removeItem(this.key);
  };
  clearAll = () => {
    localStorage.clear();
  };

}

const StorageService = {
  token: new SecureStorage("token"),
  user: new SecureStorage("user"),
};
export default StorageService;
