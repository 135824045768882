import { TableCell, TableRow, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Accidents } from "src/interfaces/Accidents";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import AccidentPdfComponent from "./AccidentPdf";

interface AccidentItemProps {
  item: Accidents;
}

const AccidentItem = ({ item }: AccidentItemProps) => {
  const [show, setShow] = useState<boolean>(false);

  const type = useMemo(() => {
    return item.type === "ACCIDENT" ? "Accident" : "Incident";
  }, [item.type]);
  const date = useMemo(() => {
    return item.date + " " + item.time;
  }, [item.date, item.time]);

  const onClickDownload = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <TableRow>
      <TableCell>
        <Typography color={'#000'}>{type}</Typography>
      </TableCell>
      <TableCell>
        <Typography color={'#000'}>{date}</Typography>
      </TableCell>
      <TableCell onClick={onClickDownload} sx={{ width: "30px" }}>
        <PDFIcon />
      </TableCell>
      {show && (
        <AccidentPdfComponent
          handleClose={() => setShow(false)}
          item={item}
          open={show}
        />
      )}
    </TableRow>
  );
};
export default AccidentItem;
