import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginType } from "src/interfaces/login";
import { SnackbarProps } from "src/interfaces/snackbar";
import LoginLayout from "../../layouts/login/login";
import { adminLogin } from "../../redux/user/actions";

const LoginPage = () => {
  const [data, setData] = useState<LoginType>({
    username: "",
    password: "", // 'password@1234',
  });
  const [errors, setErrors] = useState<LoginType | undefined>();
  const [snack, setSnack] = useState<SnackbarProps>({
    open: false,
    message: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setValues = useCallback((value: string, key: string) => {
    setData((data) => {
      return {
        ...data,
        [key]: value,
      };
    });
  }, []);
  const validate = useCallback(() => {
    const errors: LoginType | any = {};
    if (data.username.trim().length < 8) {
      errors.username = "Enter valid username";
    }
    if (data.password.trim().length < 8) {
      errors.password = "Enter valid password";
    }

    if (Object.keys(errors).length === 0) return true;
    throw errors;
  }, [data]);

  const onClickLogin = useCallback(async () => {
    try {
      validate();
      await dispatch(adminLogin(data));
      navigate("/");
    } catch (e: any) {
      if (e?.username || e?.password) {
        setErrors(e as LoginType);
      }else{
        setSnack({
          open:true,
          message:e?.message
        })
      }
    }
  }, [navigate, dispatch, validate,data]);

  const handleClose = useCallback(() => {
    setSnack({
      open: false,
      message: "",
    });
  }, []);

  return (
    <LoginLayout
      onClickLogin={onClickLogin}
      data={data}
      errors={errors}
      setValues={setValues}
      handleClose={handleClose}
      snackbar={snack}
    />
  );
};

export default LoginPage;
