import Storage from "../../storage";
import { UserTypes } from "./types";

const initialState = {
  user: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.LOGIN + "_SUCCESS":
      Storage.token.setValue(action.payload.accessToken);
      Storage.user.setValue(action.payload.data);
      return {
        ...state,
        user: action.payload.data,
      };

    case UserTypes.GET_USER + "_SUCCESS":
      if (action.payload.data?.user_uuid) {
        Storage.user.setValue(action.payload.data);
        return {
          ...state,
          user: {...action.payload.data},
        };
      } else {
        return {
          ...state,
        };
      }
    case UserTypes.GET_USER + "_FAIL":
      Storage.token.clear();
      Storage.user.clear();
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};
