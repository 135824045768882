import DashboardLayout from "../../layouts/dashboard";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GraphDataProps } from "src/interfaces/dashboard";
import { DropdownOptions } from "@components/Dropdown";
import { getAllUserTypes } from "src/redux/userManagement/actions";
import moment from "moment";
import {
  getDailyReports,
  getSafetyReports,
  getTrainingReports,
  getWeeklyReports,
  getOtherTrainingReports
} from "src/redux/reports/actions";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "src/constants/RouterPath";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stores: any[] = useSelector(
    (state: any) => state.userManagement.stores
  );
  const managers: any[] = useSelector(
    (state: any) => state.userManagement.managers
  );

  const user = useSelector((state: any) => state.user.user);

  const dailyReports = useSelector((state: any) => state.reports.daily);
  const weeklyReports = useSelector((state: any) => state.reports.weekly);
  const safetyReports = useSelector((state: any) => state.reports.safety);
  const trainingReports = useSelector((state: any) => state.reports.training);
  const otherTrainingReports = useSelector((state: any) => state.reports.otherTraining);

  const [dataSelector, setDataSelector] = useState<GraphDataProps>({
    dataSelector: "all",
    date_selector: "today",
    from_date: moment().format("YYYY-MM-DD").toString(),
    to_date: moment().format("YYYY-MM-DD").toString(),
  });

  useEffect(() => {
    if (user?.is_password_change === 0) {
      navigate(RouterPath.setPassword);
    }
  }, [user,navigate]);

  useEffect(() => {
    dispatch(getAllUserTypes());
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      to_date: dataSelector.to_date,
      from_date: dataSelector.from_date,
      store_id: dataSelector?.store_id,
      area_manager_id: dataSelector?.area_manager_id,
    };
    dispatch(getDailyReports(payload));
    dispatch(getWeeklyReports(payload));
    dispatch(getSafetyReports(payload));
    dispatch(getTrainingReports(payload));
    dispatch(getOtherTrainingReports(payload));
  }, [dataSelector, dispatch]);

  const storesList = useMemo<DropdownOptions[]>(() => {
    return stores.map((item: any) => {
      return {
        value: item?.user_uuid,
        label: `${item.username} - ${item.shop_id}`,
      } as DropdownOptions;
    });
  }, [stores]);

  const managerList = useMemo<DropdownOptions[]>(() => {
    return managers.map((item: any) => {
      return {
        value: item?.user_uuid,
        label: `${item.manager_name} - ${item.shop_region}`,
      } as DropdownOptions;
    });
  }, [managers]);
  const onChangeDateSelector = useCallback(
    (val: string) => {
      const today = moment().format("YYYY-MM-DD").toString();
      const fromDate =
        val === "today"
          ? today
          : val === "weekly"
          ? moment().subtract({ days: 7 }).format("YYYY-MM-DD").toString()
          : val === "monthly"
          ? moment().set({ date: 1 }).format("YYYY-MM-DD").toString()
          : val === "custom"
          ? dataSelector.from_date
          : today;

      setDataSelector({
        ...dataSelector,
        date_selector: val,
        from_date: fromDate,
        to_date: today,
      });
    },
    [dataSelector]
  );
  const onChangeDataSelector = useCallback(
    (val: string) => {
      setDataSelector({
        ...dataSelector,
        dataSelector: val,
        store_id: val === "store_selector" ? storesList?.[0]?.value : undefined,
        area_manager_id:
          val === "area_selector" ? managerList?.[0]?.value : undefined,
      });
    },
    [dataSelector, storesList, managerList]
  );
  const onChangeDropdownValue = useCallback(
    (key: string, val: string) => {
      setDataSelector({
        ...dataSelector,
        [key]: val,
      });
    },
    [dataSelector]
  );

  return (
    <DashboardLayout
      dataSelector={dataSelector}
      onChangeDataSelector={onChangeDataSelector}
      storesList={storesList}
      onChangeDropdownValue={onChangeDropdownValue}
      onChangeDateSelector={onChangeDateSelector}
      managerList={managerList}
      dailyReports={dailyReports}
      weeklyReports={weeklyReports}
      safetyReports={safetyReports}
      trainingReports={trainingReports}
      otherTrainingReports={otherTrainingReports}
    />
  );
};

export default DashboardPage;
