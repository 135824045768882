import { useDispatch, useSelector } from "react-redux";
import ExternalTrainingsLayout from "../../layouts/externalTrainings";
import React, { useCallback, useEffect, useState } from "react";
import { TrainingForm } from "../../layouts/externalTrainings/TrainingLayout";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
import { getUserData } from "src/redux/user/actions";
import { FormikHelpers } from "formik";
import { AddTrainingForm } from "../../layouts/externalTrainings/AddTraining";
import { ExternalTrainingType } from "src/interfaces/ExternalTrainings";

const ExternalTrainings = () => {
  const user = useSelector((state: any) => state.user.user);

  const dispatch = useDispatch();

  const [trainings, setTrainings] = useState<ExternalTrainingType[]>([]);

  const getTrainings = useCallback(async () => {
    try {
      const result = await getHttpClient(HTTPService.OTHER_TRAINING);
      setTrainings(result.data.data);
    } catch (e) {
      // TODO e
    }
  }, []);

  useEffect(() => {
    getTrainings();
  }, [getTrainings]);

  const onTrainingSubmit = useCallback(
    async (values: TrainingForm) => {
      try {
        await getHttpClient(HTTPService.UPDATE_INTERNAL_TRAINING, "put", {
          ...values,
        });
        dispatch(getUserData());
      } catch (e) {
        // TODO e
      }
    },
    [dispatch]
  );
  const onAddTrainings = useCallback(
    async (
      values: AddTrainingForm,
      formikHelpers: FormikHelpers<AddTrainingForm>
    ) => {
      try {
        const result = await getHttpClient(
          HTTPService.OTHER_TRAINING,
          "post",
          {
            ...values,
          }
        );
        formikHelpers.resetForm()
        setTrainings(result.data.data);
      } catch (e) {
        // TODO e
      }
    },
    []
  );

  const onClickDelete = useCallback(async (item: ExternalTrainingType) => {
    try {
      const result = await getHttpClient(
        HTTPService.OTHER_TRAINING,
        "delete",
        {
          id: item.id,
        }
      );
      setTrainings(result.data.data);
    } catch (e) {
      // TODO e
    }
  }, []);

  return (
    <ExternalTrainingsLayout
      internalTraining={user?.internalTraining}
      onTrainingSubmit={onTrainingSubmit}
      onAddTrainings={onAddTrainings}
      trainings={trainings}
      onClickDelete={onClickDelete}
    />
  );
};
export default ExternalTrainings;
