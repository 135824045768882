import React, { useState, useCallback } from "react";
import { Question } from "src/interfaces/questions";
import { Card, Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import TextInput from "../../../components/TextInput";
import { useDispatch } from "react-redux";
import { deleteQuestionById, updateQuestionById } from "src/redux/questions/actions";

interface EditQuestionProps {
  question: Question;
  index: number;
}
const EditQuestion = ({ question, index }: EditQuestionProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<string>("");
  const dispatch = useDispatch();

  const onClickEditOrSave = useCallback(() => {
    if (isEditing) {
      dispatch(
        updateQuestionById({
          question_id: question.id,
          question: value,
        })
      );
    } else {
      setValue(question?.question);
    }
    setIsEditing(!isEditing);
  }, [isEditing, question, value,dispatch]);

  const onClickCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onClickDelete = useCallback(()=>{
    dispatch(deleteQuestionById(question.id))
  },[dispatch,question])

  return (
    <Card
      sx={{
        padding: "12px",
        margin: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          width: "100%",
        }}
      >
        <Typography color={"text.secondary"}>{index + 1}.</Typography>
        {isEditing ? (
          <TextInput
            value={value}
            onChange={setValue}
            sx={{
              margin: "0px 8px",
              width: "100%",
            }}
          />
        ) : (
          <Typography color={"text.secondary"} margin={"0px 8px"}>
            {question.question}
          </Typography>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Typography color={"text.secondary"}>
          {question.questionType}
        </Typography>
        <Box
          sx={{
            padding: "4px 4px",
            marginLeft: "8px",
          }}
          onClick={onClickEditOrSave}
        >
          {isEditing ? (
            <SaveIcon sx={{ color: "text.secondary" }} fontSize={"small"} />
          ) : (
            <EditIcon sx={{ color: "text.secondary" }} fontSize={"small"} />
          )}
        </Box>
        <Box
          sx={{
            padding: "4px 4px",
            marginLeft: "8px",
          }}
          onClick={isEditing?onClickCancel:onClickDelete}
        >
          {isEditing ? (
            <CancelIcon sx={{ color: "text.secondary" }} fontSize={"small"} />
          ) : (
            <DeleteIcon sx={{ color: "text.secondary" }} fontSize={"small"} />
          )}
        </Box>
      </Box>
    </Card>
  );
};
export default EditQuestion;
