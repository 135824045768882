import { Box, Card } from "@mui/material";
import React from "react";
import { Question } from "src/interfaces/questions";
import AddQuestion from "./components/addQuestion";
import EditQuestion from "./components/editQuestion";

interface EditQuestionsProps {
  questions: Question[];
}

const EditQuestionLayout = ({ questions }: EditQuestionsProps) => {
  return (
    <Box>
      <Card
        sx={{
          padding: "16px",
          margin: "8px",
        }}
      >
        <AddQuestion />

        {questions.map((item: Question, index) => {
          return <EditQuestion question={item} index={index} key={item.id} />;
        })}
      </Card>
    </Box>
  );
};

export default EditQuestionLayout;
