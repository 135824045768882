import EditQuestionLayout from "../../layouts/questions/edit.layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuestionByCategory } from "src/redux/questions/actions";

const EditQuestionsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const questions = useSelector((state: any) => state.questions.questions);

  useEffect(() => {
    if (location?.state?.categoryId) {
      dispatch(getQuestionByCategory(location?.state?.categoryId));
    } else {
      navigate("/questions");
    }
  }, [location, navigate,dispatch]);

  return <EditQuestionLayout questions={questions} />;
};
export default EditQuestionsPage;
