import FilePicker from "../../components/FilePicker";
import TextInput from "../../components/TextInput";
import { Card, Grid, Typography, useTheme } from "@mui/material";
import { MixTheme } from "@styles";
import { FormikHelpers, useFormik } from "formik";
import React, { useCallback } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

interface SendNotificationProps {
  onClickSendNotification: (
    data: NotificationType,
    formikHelpers: FormikHelpers<NotificationType>
  ) => void;
  isLoading: boolean;
}
export interface NotificationType {
  message: string;
  title: string;
  file?: File;
}

const Schema = Yup.object().shape({
  title: Yup.string().required("Please enter a title"),
  message: Yup.string().required("Please enter a message"),
});
const SendNotification = ({
  onClickSendNotification,
  isLoading
}: SendNotificationProps) => {
  const theme = useTheme<MixTheme>();

  const { handleSubmit, values, errors, handleChange, setFieldValue } =
    useFormik({
      initialValues: {
        title: "",
        message: "",
      } as NotificationType,
      validationSchema: Schema,
      onSubmit: onClickSendNotification,
    });

  const onSelectFile = useCallback(
    (file: File) => {
      setFieldValue("file", file);
    },
    [setFieldValue]
  );

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#FFF",
          padding: "32px",
          flexDirection: "column",
        }}
      >
        <Typography
          fontWeight={"700"}
          fontSize={"28px"}
          fontFamily={theme.font?.tertiary}
          color={theme.palette?.text?.primary}
          textAlign={"center"}
        >
          Communications
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6} lg={3}>
            <TextInput
              sx={{
                width: "100%",
              }}
              label={"Title"}
              value={values?.title}
              error={errors?.title}
              field={"Title"}
              onChange={handleChange("title")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextInput
              sx={{
                width: "100%",
              }}
              label={"Message"}
              value={values?.message}
              field={"Message"}
              error={errors?.message}
              onChange={handleChange("message")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FilePicker onSelect={onSelectFile} file={values.file} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LoadingButton
                loading={isLoading}
              variant="contained"
              sx={{
                margin: "16px 0px 16px 0px",
              }}
              onClick={() => handleSubmit()}
            >
              Send Notification
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default SendNotification;
