import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { SnackbarProps } from "src/interfaces/snackbar";
interface TyfhuSnackProps {
  snackbar: SnackbarProps;
  handleClose: () => void;
}
const TyfhuSnack = ({ snackbar, handleClose }: TyfhuSnackProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={snackbar?.open}
      onClose={handleClose}
      message={snackbar?.message}
      autoHideDuration={3000}
    >
      <Alert onClose={handleClose} severity={snackbar?.severity ?? 'error'} sx={{ width: "100%" }}>
        {snackbar?.message}
      </Alert>
    </Snackbar>
  );
};
export default TyfhuSnack;
