import DrawerLayout from "../components/Drawer";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import DashboardPage from "src/pages/dashboard";
import LoginPage from "../pages/login";
import AreaManagemerPage from "../pages/areaManager";
import StoresPage from "../pages/stores";
import CreateAreaManagerPage from "../pages/areaManager/createAreaManager";
import StoreReportsPage from "../pages/stores/report";
import AreaReportsPage from "../pages/areaManager/report";
import QuestionsPage from "../pages/questions";
import EditQuestionsPage from "../pages/questions/edit.page";
import ReportDetailsPage from "../pages/reports/details";
import PdfContainer from "../pages/pdfpage";
import { RouterPath } from "src/constants/RouterPath";
import SetPassword from "../pages/setPassword";
import CompanyAdminSettings from "../pages/companyAdmin/settings";
import Gallery from "../pages//gallery";
import Accident from "../pages/accident";
import NotificationPage from "../pages/notifications";
import ExternalTrainings from "../pages/externalTrainings";
import ProductPromotions from "../pages/productPromotions";
import ProductPromotionDetails from "../pages/productPromotionDetails";

export const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <>
          <DrawerLayout />
        </>
      }
      errorElement={<DrawerLayout />}
    >
      <Route path="/" index element={<DashboardPage />} />
      <Route path="/reports" index element={<DashboardPage />} />
      <Route path="/dashboard" index element={<DashboardPage />} />
      <Route path="/lead">
        <Route index element={<AreaManagemerPage />} />
        <Route
          path="/lead/create"
          index
          element={<CreateAreaManagerPage />}
        />
        <Route
          path="/lead/edit"
          index
          element={<CreateAreaManagerPage />}
        />
        <Route
          path="/lead/reports"
          index
          element={<AreaReportsPage />}
        />
      </Route>
      <Route path="/stores">
        <Route index element={<StoresPage />} />
        <Route path="/stores/reports" element={<StoreReportsPage />} />
      </Route>
      <Route path="/questions">
        <Route index element={<QuestionsPage />} />
        <Route path="/questions/edit" element={<EditQuestionsPage />} />
      </Route>
      <Route path="/reports/details" element={<ReportDetailsPage />} />
      <Route path={RouterPath.setPassword} element={<SetPassword />} />
      <Route path={RouterPath.settings} element={<CompanyAdminSettings />} />
      <Route path={"/gallery"} element={<Gallery />} />
      <Route path={"/accident"} element={<Accident />} />
      <Route path={"/notifications"} element={<NotificationPage />} />
      <Route path={"/trainings"} element={<ExternalTrainings />} />
      <Route path={"/promotions"} element={<ProductPromotions />} />
      <Route path={"/promotions/images"} element={<ProductPromotionDetails />} />
    </Route>
  )
);
export const areaManagerRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <>
          <DrawerLayout />
        </>
      }
      errorElement={<DrawerLayout />}
    >
      <Route path="/" index element={<DashboardPage />} />
      <Route path="/reports" index element={<DashboardPage />} />
      <Route path="/dashboard" index element={<DashboardPage />} />
      <Route path="/stores">
        <Route index element={<StoresPage />} />
        <Route path="/stores/reports" element={<StoreReportsPage />} />
      </Route>
      
      <Route path={RouterPath.setPassword} element={<SetPassword />} />
      
      <Route path={"/gallery"} element={<Gallery />} />
      <Route path={"/accident"} element={<Accident />} />

      <Route path={"/promotions"} element={<ProductPromotions />} />
      <Route path={"/promotions/images"} element={<ProductPromotionDetails />} />
    </Route>
  )
);

export const loginRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <>
          <Outlet />
        </>
      }
      errorElement={<LoginPage />}
    >
      <Route  path="/" index element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/pdf/:token" element={<PdfContainer />} />
      
    </Route>
  )
);

