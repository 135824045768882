import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUserTypes } from "src/redux/userManagement/actions";
import StoresListLayout from "../../layouts/stores";

const StoresPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getAllUserTypes());
  }, [dispatch]);

  const onClickChart = useCallback((item:any)=>{
    navigate("/stores/reports", {
      state: {
        store_id:item?.user_uuid,
        ...item
      },
    });
  },[navigate])

  return <StoresListLayout 
  onClickChart={onClickChart}
  />;
};
export default StoresPage;
