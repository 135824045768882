import {HTTPService} from '../../services/httpservice';
import {ReportTypes} from './types';

export const getDailyReports = data => {
  return {
    type: ReportTypes.DAILY,
    request: {
      path: HTTPService.DAILY,
      method: 'POST',
      data,
    },
  };
};

export const getWeeklyReports = data => {
  return {
    type: ReportTypes.WEEKLY,
    request: {
      path: HTTPService.WEEKLY,
      method: 'POST',
      data,
    },
  };
};

export const getSafetyReports = data => {
  return {
    type: ReportTypes.SAFETY,
    request: {
      path: HTTPService.SAFETY,
      method: 'POST',
      data,
    },
  };
};

export const getTrainingReports = data => {
  return {
    type: ReportTypes.REPORTS_TRAINING,
    request: {
      path: HTTPService.REPORTS_TRAINING,
      method: 'POST',
      data,
    },
  };
};

export const getOtherTrainingReports = data => {
  return {
    type: ReportTypes.REPORTS_OTHER_TRAINING,
    request: {
      path: HTTPService.REPORTS_OTHER_TRAINING,
      method: 'POST',
      data,
    },
  };
};
