import React from "react";
import TextInput from "src/components/TextInput";
import { Box, Button, Typography } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";

import * as Yup from "yup";
export interface AddTrainingForm {
  name: string;
}
interface TrainingProps {
  onAddTrainings: (
    values: AddTrainingForm,
    formikHelpers: FormikHelpers<AddTrainingForm>
  ) => void;
}

const TrainingSchema = Yup.object().shape({
  name: Yup.string().min(3).required("Please enter name of training"),
});
const AddTrainingLayout = ({ onAddTrainings }: TrainingProps) => {
  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: onAddTrainings,
    validationSchema: TrainingSchema,
  });

  return (
    <>
      <Typography fontWeight={"700"} color={"black"} marginTop={"32px"}>
        {"Create New Training"}
      </Typography>
      <Box display={"flex"} alignItems={"center"}>
        <TextInput
          label="Name"
          field="internalTraining"
          sx={{
            width: "50%",
          }}
          value={values?.name}
          error={errors?.name}
          onChange={handleChange("name")}
        />

        <Button
          variant="contained"
          sx={{
            marginLeft: "8px",
            height: "35px",
          }}
          onClick={() => handleSubmit()}
        >
          {`Add`}
        </Button>
      </Box>
    </>
  );
};

export default AddTrainingLayout;
