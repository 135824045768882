import { HTTPService } from "src/services/httpservice";
import { CompanyTypes } from "./types";

export const getCompanies = (data: any) => {
  return {
    type: CompanyTypes.GET_COMPANIES,
    request: {
      path: HTTPService.GET_COMPANIES,
      method: "POST",
      data,
    },
  };
};

export const createCompany = (data: any) => {
  return {
    type: CompanyTypes.ADD_COMPANY,
    request: {
      path: HTTPService.ADD_COMPANY,
      method: "POST",
      data,
    },
  };
};
