import { Box, Grid } from "@mui/material";
import React from "react";
import GalleryFilters, { GalleryFilterData } from "./GalleryFilters";
import { DropdownOptions } from "@components/Dropdown";

interface GalleryLayoutProps {
  dataSelector: GalleryFilterData;
  storesList?: DropdownOptions[];
  onChangeDropdownValue: (key: string, val: string) => void;
  images: string[];
}
const Gallerylayout = ({
  dataSelector,
  images = [],
  onChangeDropdownValue,
  storesList = [],
}: GalleryLayoutProps) => {
  return (
    <Box>
      <GalleryFilters
        storesList={storesList}
        dataSelector={dataSelector}
        onChangeDropdownValue={onChangeDropdownValue}
      />
      <Grid container spacing={2}>
        {images.map((item) => {
          return <Grid item key={item.toString()} style={{width:'200px',margin:'16px'}}>
            <img src={item} height={'200px'} width={'200px'} alt="Gallery"/>
          </Grid>;
        })}
      </Grid>
    </Box>
  );
};
export default Gallerylayout;
