import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import { MixTheme } from "../../styles";
import { ProductPromotionType } from "src/interfaces/ProductPromotion";

import GalleryIcon from '@mui/icons-material/BrowseGalleryOutlined'
import { useNavigate } from "react-router-dom";
interface GridColDef {
  field: string;
  headerName: string;
}
const Columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Product Name",
  },
  {
    field: "date",
    headerName: "Date",
  },
  {
    field: "",
    headerName: "Details",
  },
];

interface PromotionListProps {
  promotions: ProductPromotionType[];
}

const PromotionsList = ({
  promotions = [],
}: PromotionListProps) => {
  const theme = useTheme<MixTheme>();
  const navigate = useNavigate();
  const onClickDetails = useCallback((item: ProductPromotionType)=>{
    navigate("/promotions/images",{
      state: {
        ...item
      }
    })
  },[navigate])

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        marginTop: "32px",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions.map((item: ProductPromotionType) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography color={theme.palette.text?.secondary}>
                      {item.title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={theme.palette.text?.secondary}>
                      {item.date}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <GalleryIcon onClick={()=>onClickDetails(item)}/>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PromotionsList;
