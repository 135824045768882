import React, { useState } from "react";
import {
  Input,
  SxProps,
  Theme,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
interface InputProps {
  sx?: SxProps<Theme>;
  value?: string;
  onChange?: (val: string, field?: string) => void;
  label: string;
  field?: string;
  error?: string;
}
const PasswordInput = ({
  sx,
  label,
  value,
  onChange,
  field,
  error,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <FormControl
      sx={{ width: "100%", margin: "8px 0px 8px 0px" }}
      variant="standard"
      error={Boolean(error)}
    >
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>

      <Input
        sx={{
          width: "100%",
          ...sx,
        }}
        value={value}
        error={Boolean(error)}
        onChange={(event) => {
          onChange?.(event.target.value, field);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        type={showPassword?"text":"password"}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export default PasswordInput;
