import { Card, Typography } from "@mui/material";
import AddTrainingLayout, { AddTrainingForm } from "./AddTraining";
import TrainingLayout, { TrainingForm } from "./TrainingLayout";
import React from "react";
import TrainingsList from "./TrainingsList";
import { FormikHelpers } from "formik";
import { ExternalTrainingType } from "src/interfaces/ExternalTrainings";

interface ExternalTrainingsLayoutProps {
  internalTraining?: string;
  onTrainingSubmit: (values: TrainingForm) => void;
  onAddTrainings: (values: AddTrainingForm,formikHelpers: FormikHelpers<AddTrainingForm> ) => void;
  trainings: ExternalTrainingType[];
  onClickDelete: (item: ExternalTrainingType) => void;
}
const ExternalTrainingsLayout = ({
  internalTraining,
  onTrainingSubmit,
  onAddTrainings,
  trainings=[],
  onClickDelete
}: ExternalTrainingsLayoutProps) => {
  return (
    <>
      <Card
        sx={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography component={"h1"} fontWeight={"700"} fontSize={"28px"}>
          Trainings
        </Typography>

        <TrainingLayout
          internalTraining={internalTraining}
          onTrainingSubmit={onTrainingSubmit}
        />
        <AddTrainingLayout onAddTrainings={onAddTrainings} />

      </Card>
      <TrainingsList trainings={trainings} onClickDelete={onClickDelete}/>
    </>
  );
};
export default ExternalTrainingsLayout;
