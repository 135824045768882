import React from "react";

import {
  Box,
  useTheme,
} from "@mui/material";

import { MixTheme } from "@styles";
import { SnackbarProps } from "src/interfaces/snackbar";
import TyfhuSnack from "../../components/TyfhuSnack";
import { FormikHelpers } from "formik";
import SendNotification, { NotificationType } from "./SendNotification";
import { NotificationData } from "src/interfaces/Notification";
import NotificationsList from "./NotificationsList";

interface NotificationProps {
  onClickSendNotification: (data: NotificationType, formikHelpers: FormikHelpers<NotificationType>) => void;
  handleClose: () => void;
  snackbar: SnackbarProps;
  data: NotificationData[];
  isLoading: boolean;
}


const NotificationLayout = ({
  onClickSendNotification,
  handleClose,
  snackbar,
  data,
  isLoading
}: NotificationProps) => {
  const theme = useTheme<MixTheme>();



  return (
    <Box
      sx={{
        backgroundColor: theme.palette?.background?.default,
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <SendNotification onClickSendNotification={onClickSendNotification} isLoading={isLoading}/>
      <NotificationsList  data={data}/>

      <TyfhuSnack snackbar={snackbar} handleClose={handleClose} />
    </Box>
  );
};
export default NotificationLayout;
