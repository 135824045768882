import {combineReducers} from 'redux';
import CompanyReducer from './companies/reducer';
import {loadingReducer} from './loading-reducer';
import questionsReducer from './questions/reducer';
import { reportReducer } from './reports/reducer';
import {userReducer} from './user/reducer';
import { userManagementReducer } from './userManagement/reducer';

const rootReducer = combineReducers({
  loadingReducer,
  user: userReducer,
  userManagement:userManagementReducer,
  reports:reportReducer,
  questions:questionsReducer,
  company:CompanyReducer
});
export default rootReducer;
