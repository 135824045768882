import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ROLES } from "src/constants/roles";

export interface DropdownOptions {
  label: string;
  value: string;
  onlyAdmin?: boolean;
}

interface DropdownProps {
  onChange?: (val: string) => void;
  options: DropdownOptions[];
  value?: string;
  title?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  error?: string;
}

const Dropdown = ({
  onChange,
  options,
  value,
  title,
  fullWidth = true,
  sx,
  error,
}: DropdownProps) => {
  const user = useSelector((state: any) => state.user.user);

  return (
    <>
      <FormControl fullWidth={fullWidth} sx={sx} error={Boolean(error)}>
        {title && (
          <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        )}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={title}
          onChange={(e) => onChange?.(e.target.value)}
        >
          {options.map((item: DropdownOptions, index) => {
            const show = item.onlyAdmin ? user.role === ROLES.ADMIN : true;
            if (show)
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            return (<></>)
          })}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default Dropdown;
