import { configureStore as createStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import apiMiddleware from './middleware';
import rootReducer from './rootReducer';
const configureStore = () => {
  return createStore({
    reducer:rootReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware().concat(thunk).concat(apiMiddleware)
  });
};
export default configureStore;
