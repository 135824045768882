import CreateCompanyLayout from "../../../layouts/admin/companies/createCompany.layout";
import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateCompanyForm, CompanyResult } from "src/interfaces/company";
import * as Yup from "yup";
import { createCompany } from "src/redux/companies/actions";
import { createLoadingSelector } from "src/redux/loading-reducer";
import { CompanyTypes } from "src/redux/companies/types";

const schema = Yup.object().shape({
  company_name: Yup.string().required("Enter company name"),
  company_country: Yup.string().required("Select a country"),
  no_of_branches: Yup.number().required("Enter number of branches"),
  restaurant_type: Yup.string().required("Select restaurant type"),
  email: Yup.string().email().required("Please enter email"),
});
const CreateCompanyPage = () => {
  const dispatch = useDispatch();

  const { values, handleChange, errors, isValid, resetForm, validateForm } =
    useFormik({
      initialValues: {} as CreateCompanyForm,
      validationSchema: schema,
      onSubmit: () => undefined,
    });
  const [result, setResult] = useState<CompanyResult>();

  const loadingSelector = createLoadingSelector([CompanyTypes.ADD_COMPANY]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const onPressCreate = useCallback(async () => {
    validateForm();
    setResult(undefined);
    if (isValid) {
      const data: any = await dispatch(createCompany(values));
      setResult(data?.data);
      resetForm();
    }
  }, [values, isValid, resetForm, dispatch, validateForm]);

  return (
    <CreateCompanyLayout
      isLoading={isLoading}
      values={values}
      errors={errors}
      handleChange={handleChange}
      onPressCreate={onPressCreate}
      result={result}
    />
  );
};
export default CreateCompanyPage;
