import { Card, Typography } from "@mui/material";
import AddTrainingLayout, { AddPromotionForm } from "./AddPromotion";
import React from "react";
import PromotionsList from "./PromotionsList";
import { FormikHelpers } from "formik";
import { ProductPromotionType } from "src/interfaces/ProductPromotion";
import { ROLES } from "src/constants/roles";

interface ProductPromotionLayoutProps {
  onAddTrainings: (values: AddPromotionForm,formikHelpers: FormikHelpers<AddPromotionForm> ) => void;
  trainings: ProductPromotionType[];
  role: string;
}
const ProductPromotionsLayout = ({
  onAddTrainings,
  trainings=[],
  role
}: ProductPromotionLayoutProps) => {
  return (
    <>
      <Card
        sx={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography component={"h1"} fontWeight={"700"} fontSize={"28px"}>
          Promotions
        </Typography>
        {
          role === ROLES.ADMIN &&
        <AddTrainingLayout onAddTrainings={onAddTrainings} />

        }

      </Card>
      <PromotionsList promotions={trainings} />
    </>
  );
};
export default ProductPromotionsLayout;
