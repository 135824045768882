import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableFooter,
  Button,
} from "@mui/material";
import { Company } from "src/interfaces/company";
import CompanyItem from "./companyItem";
interface CompanyProps {
  count: number;
  onClickItem: (item: any) => void;
  companies: Company[];
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (page: number) => void;
  page: number;
  rowsPerPage: number;
  onClickCreate: () => void;

  onClickDelete: (item: Company) => void;
  onClickDisable: (item: Company) => void;
}

interface GridColDef {
  field: string;
  headerName: string;
}
const Columns: GridColDef[] = [
  {
    field: "company_name",
    headerName: "Name",
  },
  {
    field: "company_country",
    headerName: "Country",
  },
  {
    field: "no_of_branches",
    headerName: "Branches",
  },
  {
    field: "restaurant_type_name",
    headerName: "Restaurant Type",
  },
  {
    field: "email",
    headerName: "Email",
  },
];
const CompanyLayout = ({
  count,
  onClickItem,
  companies,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  onClickCreate,
  onClickDelete,
  onClickDisable,
}: CompanyProps) => {

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        sx={{
          width: "200px",
          alignSelf: "flex-end",
        }}
        variant={"contained"}
        onClick={onClickCreate}
      >
        {"Create New Company"}
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell>
                <Typography fontWeight={"700"}>{"Disable"}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={"700"}>{"Delete"}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((item: Company) => {
              return (
                <CompanyItem
                  key={item.company_uuid}
                  Columns={Columns}
                  item={item}
                  onClickDelete={onClickDelete}
                  onClickDisable={onClickDisable}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, page) => onPageChange(page)}
              onRowsPerPageChange={(e) =>
                onRowsPerPageChange(parseInt(e.target.value, 10))
              }
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default CompanyLayout;
