import React, { useEffect,useMemo } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import  { areaManagerRouter, loginRouter,adminRouter } from "./routes";
import { styles, theme } from "./styles";
import {
  Box,
  CircularProgress,
  GlobalStyles,
  ThemeProvider,
} from "@mui/material";
import {
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import "./styles/fonts.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import configureStore from "./redux/configureStore";
import StorageService from "./storage";
import { getUserData } from "./redux/user/actions";
import { createLoadingSelector } from "./redux/loading-reducer";
import { UserTypes } from "./redux/user/types";
import { ROLES } from "./constants/roles";
import { appAdminRouter } from "./routes/AppAdminRouter";
import { Worker } from "@react-pdf-viewer/core";

const Root = () => {
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([UserTypes.GET_USER]);
  const isLoading = useSelector((state) => loadingSelector(state));
  useEffect(() => {
    if (StorageService.token.getValue()) {
      dispatch(getUserData());
    }
  }, [dispatch]);

  const router = useMemo (()=>{
    if(user?.role === ROLES.APPADMIN) return appAdminRouter;
    if(user?.role === ROLES.ADMIN) return adminRouter;
    if(user?.role === ROLES.AREAMANAGER) return areaManagerRouter;
    return loginRouter;
  },[user])
  
  if (isLoading) {
    return (
      <Box>
        <CircularProgress color="success" />
      </Box>
    );
  }

  return <RouterProvider router={router} />;
};

function App() {
  const Store = configureStore();

  return (
    <ThemeProvider theme={theme}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalStyles styles={styles} />
        <Provider store={Store}>
          <Root />
        </Provider>
      </LocalizationProvider>
      </Worker>
    </ThemeProvider>
  );
}

export default App;
