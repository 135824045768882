// import Snack from "../../components/Snackbar";
// import {CTToast} from '../../components/toast';
import {getHttpClient} from '../../services/rest-api';
// import {UserManager} from 'src/storage';

const apiMiddleware = store => next => action => {
  // //console.log("action",action.type);
  if (action.hasOwnProperty('request')) {
    return new Promise((resolve, reject) => {
      const {request} = action;
      let params = request.params;
      const param = action;
      delete param.request;
      let headerData = {};

      store.dispatch({...param, type: param.type + '_REQUEST'});
      getHttpClient(
        request.path,
        request.method,
        request.data,
        params,
        headerData,
      )
        .then(response => {
          // //console.log("middle ware res", response.data);
          // //console.log('middle ware res', response.data);
          store.dispatch({
            type: action.type + '_SUCCESS',
            payload: response.data,
          });
          /* offline save data */
          resolve(response.data);
          // next(action);
        })
        .catch(e => {
          console.log('middle ware error', e, e?.message);
          if (e?.message) {
            // CTToast(e?.message, 'error');
          }
          store.dispatch({
            type: action.type + '_FAIL',
            error: e,
          });
          reject(e);
          // next(action);
        });
    });
  } else {
    next(action);
  }
};

export default apiMiddleware;
