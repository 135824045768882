import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { MixTheme } from "../../styles";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { deleteAreaManager } from "src/redux/userManagement/actions";
import PieChartIcon from "@mui/icons-material/PieChart";

interface GridColDef {
  field: string;
  headerName: string;
}

const Columns: GridColDef[] = [
  {
    field: "manager_name",
    headerName: "Name",
  },
  {
    field: "shop_region",
    headerName: "Area",
  },
  {
    field: "username",
    headerName: "Username",
  },
  {
    field: "email",
    headerName: "Email",
  },
  {
    field: "storeCount",
    headerName: "Stores",
  },
];

const ManagerListLayout = () => {
  const managers = useSelector((state: any) => state.userManagement.managers);
  const theme = useTheme<MixTheme>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCickEdit = useCallback(
    (item: any) => {
      navigate("/lead/edit", {
        state: {
          item,
        },
      });
    },
    [navigate]
  );
  const onCickDelete = useCallback(
    (id: string) => {
      dispatch(
        deleteAreaManager({
          id,
        })
      );
    },
    [dispatch]
  );
  const onClickChart = useCallback(
    (item: any) => {
      navigate("/lead/reports", {
        state: {
          area_manager_id: item?.user_uuid,
          ...item
        },
      });
    },
    [navigate]
  );

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managers &&
              managers.map((item: any) => {
                return (
                  <TableRow>
                    {Columns.map((column) => {
                      return (
                        <TableCell key={item.headerName}>
                          <Typography color={theme.palette.text?.secondary}>
                            {item[column.field]}
                          </Typography>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      onClick={() => onCickEdit(item)}
                      sx={{ width: "30px" }}
                    >
                      <EditIcon />
                    </TableCell>
                    <TableCell
                      onClick={() => onCickDelete(item?.user_uuid)}
                      sx={{ width: "30px" }}
                    >
                      <DeleteIcon />
                    </TableCell>
                    <TableCell
                      onClick={() => onClickChart(item)}
                      sx={{ width: "30px" }}
                    >
                      <PieChartIcon />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManagerListLayout;
