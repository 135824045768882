import Dropdown, { DropdownOptions } from "../../components/Dropdown";
import { Box, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";

export interface AccidentFilterData {
  store_id?: string;
  from_date: string;
  to_date: string;
}

interface AccidentFiltersProps {
  dataSelector: AccidentFilterData;
  storesList?: DropdownOptions[];
  onChangeDropdownValue: (key: string, val: string) => void;
}

const AccidentFilters = ({
  dataSelector,
  onChangeDropdownValue,
  storesList = [],
}: AccidentFiltersProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box width={"200px"}>
        <Dropdown
          options={storesList}
          value={dataSelector?.store_id ?? ""}
          onChange={(val) => onChangeDropdownValue("store_id", val)}
        />
      </Box>
      <Grid container spacing={2} justifyContent={"flex-end"}>
        <Grid item xs={6} lg={2}>
          <DatePicker
            inputFormat="YYYY/MM/DD"
            value={dataSelector?.from_date}
            maxDate={dataSelector?.to_date}
            label={"From"}
            onChange={(val) =>
              onChangeDropdownValue(
                "from_date",
                moment(val).format("YYYY-MM-DD").toString() ??
                  dataSelector?.from_date
              )
            }
            minDate={moment().subtract("3","months").format("YYYY-MM-DD")}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={6} lg={2}>
          <DatePicker
            inputFormat="YYYY/MM/DD"
            value={dataSelector?.to_date}
            label={"To"}
            minDate={dataSelector?.from_date}
            maxDate={moment().format("YYYY-MM-DD")}
            onChange={(val) =>
              onChangeDropdownValue(
                "to_date",
                moment(val).format("YYYY-MM-DD").toString() ??
                  dataSelector?.to_date
              )
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AccidentFilters;
