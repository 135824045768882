import React from "react";
import CompanyDetails from "./CompanyDetails";



const CompanyAdminSettingsLayout = () => {
  return (
    <>
      <CompanyDetails />
    </>
  );
};
export default CompanyAdminSettingsLayout;
