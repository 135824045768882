import Dropdown, { DropdownOptions } from "../../../components/Dropdown";
import React from "react";
import { GraphDataProps } from "src/interfaces/dashboard";
import {  Grid, TextField, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const storeSelector: DropdownOptions[] = [
  {
    label: "All Stores",
    value: "all",
  },
  {
    label: "Store Selector",
    value: "store_selector",
  },
  {
    label: "Area Selector",
    value: "area_selector",
    onlyAdmin:true,
  },
];
const dateSelector: DropdownOptions[] = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

interface FilterProps {
  dataSelector: GraphDataProps;
  storesList?: DropdownOptions[];
  managerList?: DropdownOptions[];
  onChangeDataSelector?: (val: string) => void;
  onChangeDateSelector: (val: string) => void;
  onChangeDropdownValue: (key: string, val: string) => void;
}

const GraphFilter = ({
  dataSelector,
  onChangeDataSelector,
  storesList = [],
  managerList = [],
  onChangeDropdownValue,
  onChangeDateSelector,
}: FilterProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid container spacing={2}>
        {(managerList.length >0 || storesList.length>0) && (
          <Grid item xs={6} lg={4}>
            <Dropdown
              options={storeSelector}
              value={dataSelector?.dataSelector}
              onChange={onChangeDataSelector}
            />
          </Grid>
        )}
        {dataSelector?.dataSelector === "store_selector" && (
          <Grid item xs={6} lg={4}>
            <Dropdown
              options={storesList}
              value={dataSelector?.store_id ?? ""}
              onChange={(val) => onChangeDropdownValue("store_id", val)}
            />
          </Grid>
        )}
        {dataSelector?.dataSelector === "area_selector" && (
          <Grid item xs={6} lg={4}>
            <Dropdown
              options={managerList}
              value={dataSelector?.area_manager_id ?? ""}
              onChange={(val) => onChangeDropdownValue("area_manager_id", val)}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} justifyContent={"flex-end"}>
        <Grid item xs={6} lg={4}>
          <Dropdown
            options={dateSelector}
            value={dataSelector?.date_selector}
            onChange={onChangeDateSelector}
          />
        </Grid>
        {dataSelector?.date_selector === "custom" && (
          <Grid item xs={6} lg={4}>
            <DatePicker
              inputFormat="YYYY/MM/DD"
              value={dataSelector?.from_date}
              label={"From"}
              onChange={(val) =>
                onChangeDropdownValue(
                  "from_date",
                  moment(val).format("YYYY-MM-DD").toString() ??
                    dataSelector?.from_date
                )
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        )}

        {dataSelector?.date_selector === "custom" && (
          <Grid item xs={6} lg={4}>
            <DatePicker
              inputFormat="YYYY/MM/DD"
              value={dataSelector?.to_date}
              label={"To"}
              onChange={(val) =>
                onChangeDropdownValue(
                  "to_date",
                  moment(val).format("YYYY-MM-DD").toString() ??
                    dataSelector?.to_date
                )
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default GraphFilter;
