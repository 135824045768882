import AdminDashbaordLayout from "../../../layouts/admin/dashboard/dashboard.layout";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "src/redux/companies/actions";
import { useNavigate } from "react-router-dom";

const AdminDashbaordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const count = useSelector((state: any) => state.company.count);

  useEffect(() => {
    dispatch(getCompanies(null));
  }, [dispatch]);

  const onClickCompanies = useCallback(() => {
    navigate('/companies')
  }, [navigate]);

  return (
    <AdminDashbaordLayout
      count={Number(count)}
      onClickCompanies={onClickCompanies}
    />
  );
};

export default AdminDashbaordPage;
