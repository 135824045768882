import React, {
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
import { ColorRing } from "react-loader-spinner";
import { Viewer } from "@react-pdf-viewer/core";
import { Accidents } from "src/interfaces/Accidents";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface ReportProps {
  open: boolean;
  handleClose: () => void;
  item: Accidents;
}
const AccidentPdfComponent = ({ handleClose, open, item }: ReportProps) => {
  const reportTemplateRef = useRef<HTMLElement>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pdf, setPdf] = useState<string>("");

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getHttpClient(
        HTTPService.ACCIDENT_REPORT,
        "get",
        null,
        {
          id: item.id,
        }
      );
      setPdf(data.data.pdf);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [item.id]);

  useEffect(() => {
    if (open && item) {
      getData();
    }
  }, [open, item, getData]);

  const handleGeneratePdf = useCallback(() => {
    // Adding the fonts.
    // doc.setFont('Inter-Regular', 'normal');

    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${item.id}-${item.type}-${item.date}-${
      item.time
    }-${moment().valueOf()}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }, [pdf, item]);
  const base64ToArrayBuffer = useCallback((base64: string): Uint8Array => {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }, []);

  const buffer = useMemo(() => {
    return base64ToArrayBuffer(pdf);
  }, [pdf, base64ToArrayBuffer]);
  return (
    <Dialog
      fullScreen={true}
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        padding: "0",
        margin: "0",
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          width: "50vw",
          height: "100%",
        }}
      >
        <Box ref={reportTemplateRef}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {item.type}
              </Typography>
            </Toolbar>
          </AppBar>
          {isLoading ? (
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </Box>
          ) : (
            <>
              <Button
                onClick={handleGeneratePdf}
                variant={"outlined"}
                sx={{
                  margin: 2,
                }}
              >
                Download
              </Button>
              <Viewer fileUrl={buffer} enableSmoothScroll />
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
export default AccidentPdfComponent;
