import { Box, Card, Typography } from "@mui/material";
import React, { useCallback, useMemo, useRef } from "react";

interface FilePickerProps {
  onSelect: (file: File) => void;
  file?: File;
}

const FilePicker = ({ onSelect, file }: FilePickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickChoose = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  }, []);

  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      onSelect(e.target.files[0]);
    }
  }, [onSelect]);

  const image = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);

  return (
    <>
      <input
        type="file"
        hidden
        ref={inputRef}
        accept="image/*"
        onChange={onSelectFile}
      />
      <Card
        sx={{
          margin: "16px 0px 8px 0px",
        }}
      >
        <Box
          padding={"16px"}
          textAlign={"center"}
          onClick={onClickChoose}
          alignItems={"center"}
          justifyContent={"space-between"}
          display={"flex"}
        >
          {image && (
            <img
              src={image}
              style={{
                width: "78px",
                height: "78px",
              }}
              alt="Notification"
            />
          )}
          <Typography color={"black"}>Choose Image</Typography>
        </Box>
      </Card>
    </>
  );
};
export default FilePicker;
