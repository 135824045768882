import QuestionsLayout from "../../layouts/questions/index.layout";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const QuestionsPage = () => {
  const categories = require("./questions.json");

  const [expanded, setExpanded] = useState<number>(-1);

  const navigate = useNavigate()


  const onClickCategoryItem = useCallback((categoryId:number)=>{
    navigate('/questions/edit',{
      state:{
        categoryId
      }
    })
  },[navigate])

  return (
    <QuestionsLayout
      categories={categories}
      expanded={expanded}
      setExpanded={setExpanded}
      onClickCategoryItem={onClickCategoryItem}
    />
  );
};
export default QuestionsPage;
