import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import AccidentFilters, { AccidentFilterData } from "./AccidentFilters";
import { DropdownOptions } from "@components/Dropdown";
import { Accidents } from "src/interfaces/Accidents";
import AccidentItem from "./AccidentItem";
interface AccidentLayoutProps {
  dataSelector: AccidentFilterData;
  storesList?: DropdownOptions[];
  onChangeDropdownValue: (key: string, val: string) => void;
  accidents: Accidents[];
}

interface GridColDef {
  field: string;
  headerName: string;
}

const Columns: GridColDef[] = [
  {
    field: "type",
    headerName: "Type",
  },
  {
    field: "date",
    headerName: "Date",
  },
];

const Accidentlayout = ({
  dataSelector,
  accidents = [],
  onChangeDropdownValue,
  storesList = [],
}: AccidentLayoutProps) => {
  return (
    <Box>
      <AccidentFilters
        storesList={storesList}
        dataSelector={dataSelector}
        onChangeDropdownValue={onChangeDropdownValue}
      />

      <TableContainer component={Paper} sx={{
        marginTop:'16px'
      }}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accidents &&
              accidents.map((item) => {
                return (
                  <AccidentItem key={item.id.toString()} item={item}/>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default Accidentlayout;
