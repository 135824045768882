import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { MixTheme } from "../../styles";
import { NotificationData } from "src/interfaces/Notification";
import moment from "moment";

interface GridColDef {
  field: string;
  headerName: string;
}
const Columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Title",
  },
  {
    field: "body",
    headerName: "Message",
  },
  {
    field: "image",
    headerName: "Image",
  },
  {
    field: "createdAt",
    headerName: "Date",
  },
];

interface TrainingListProps {
  data: NotificationData[];
}

const NotificationsList = ({ data = [] }: TrainingListProps) => {
  const theme = useTheme<MixTheme>();

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        marginTop: "32px",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stores table">
          <TableHead>
            <TableRow>
              {Columns.map((item) => {
                return (
                  <TableCell key={item.headerName}>
                    <Typography fontWeight={"700"}>
                      {item.headerName}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: NotificationData) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography color={theme.palette.text?.secondary}>
                      {item.title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={theme.palette.text?.secondary}>
                      {item.body}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {item?.url && (
                      <img
                        alt={item.title}
                        src={item?.url}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography color={theme.palette.text?.secondary}>
                      {moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NotificationsList;
