import SetPasswordLayout, {
  SetPasswordType,
} from "src/layouts/setPassword/setPassword.layout";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "src/constants/RouterPath";
import { getUserData } from "src/redux/user/actions";
import { HTTPService } from "src/services/httpservice";
import { getHttpClient } from "src/services/rest-api";

const SetPassword = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSetPassword = useCallback(async (values: SetPasswordType) => {
    // setIsLoading(true);
    try {
      await getHttpClient(HTTPService.SET_PASSWORD, "PUT", values);
      await dispatch(getUserData());
      navigate(RouterPath.dashboard)
    } catch (e) {}
    // setIsLoading(false);
  }, [navigate,dispatch]);

  return <SetPasswordLayout onSetPassword={onSetPassword} />;
};
export default SetPassword;
