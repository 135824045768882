import React,{useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const ReportDetailsPage = ()=>{
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(()=>{
        if(location.state){
            console.log("location.state",location.state)
        }else {
            navigate("/")
        }
    },[location,navigate])

    return (
        <></>
    )
}
export default ReportDetailsPage