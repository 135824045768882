import { HTTPService } from "src/services/httpservice";
import { QuestionTypes } from "./types";

export const getQuestionByCategory = (category_id: number) => {
  return {
    type: QuestionTypes.QUESTION_BY_CATEGORY,
    request: {
      path: HTTPService.QUESTION_BY_CATEGORY,
      method: "GET",
      params: {
        category_id,
      },
    },
  };
};
export const updateQuestionById = (data:any) => {
  return {
    type: QuestionTypes.EDIT_QUESTION,
    request: {
      path: HTTPService.EDIT_QUESTION,
      method: "PUT",
      data
    },
  };
};
export const deleteQuestionById = (id:any) => {
  return {
    type: QuestionTypes.DELETE_QUESTION,
    request: {
      path: HTTPService.EDIT_QUESTION,
      method: "DELETE",
      params:{
        id
      }
    },
  };
};
export const addQuestion = (data:any) => {
  return {
    type: QuestionTypes.ADD_QUESTION,
    request: {
      path: HTTPService.ADD_QUESTION,
      method: "POST",
      data
    },
  };
};
