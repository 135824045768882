import { FormikHelpers } from "formik";
import NotificationLayout from "../../layouts/notifications/notifications";
import React, { useCallback, useEffect, useState } from "react";
import { SnackbarProps } from "src/interfaces/snackbar";
import { HTTPService } from "src/services/httpservice";
import { getHttpClient } from "src/services/rest-api";
import { getBase64 } from "src/utils/file";
import { NotificationType } from "../../layouts/notifications/SendNotification";
import { NotificationData } from "src/interfaces/Notification";

const NotificationPage = () => {
  const [showSnackBar, setShowSnackBar] = useState<SnackbarProps>({
    open: false,
    message: "",
  });

  const [data, setData] = useState<NotificationData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getNotifications = useCallback(async () => {
    try {
      const result = await getHttpClient(HTTPService.SEND_NOTIFICATION);
      setData(result.data.data);
    } catch {}
  }, []);

  useEffect(()=> {
    getNotifications()
  },[getNotifications])

  const handleClose = useCallback(() => {
    setShowSnackBar({
      open: false,
      message: "",
    });
  }, []);

  const handleSendNotification = useCallback(
    async (
      data: NotificationType,
      formikHelpers: FormikHelpers<NotificationType>
    ) => {
      try {
        setIsLoading(true)
        const request = {
          title: data.title,
          message: data.message,
          image: data.file ? await getBase64(data.file) : undefined,
        };
        await getHttpClient(HTTPService.SEND_NOTIFICATION, "POST", request);
        formikHelpers.resetForm();
        setShowSnackBar({
          message: "Notification sent successfully",
          open: true,
          severity: "success",
        });
        setIsLoading(false)
        getNotifications()
      } catch (e) {
        setIsLoading(false)
        setShowSnackBar({
          message: "Notification failed",
          open: true,
        });
      }
    },
    [getNotifications]
  );

  return (
    <>
      <NotificationLayout
        handleClose={handleClose}
        onClickSendNotification={handleSendNotification}
        snackbar={showSnackBar}
        data={data}
        isLoading={isLoading}
      />
    </>
  );
};
export default NotificationPage;
