import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Company } from "src/interfaces/company";
import DeleteIcon from "@mui/icons-material/Delete";
import DisableIcon from "@mui/icons-material/Clear";
import EnableIcon from "@mui/icons-material/Check";
import { MixTheme } from "@styles";

interface GridColDef {
  field: string;
  headerName: string;
}
interface CompanyItemProps {
  item: Company;
  onClickDelete: (item: Company) => void;
  onClickDisable: (item: Company) => void;
  Columns: GridColDef[];
}

const CompanyItem = ({
  Columns,
  item,
  onClickDelete,
  onClickDisable,
}: CompanyItemProps) => {
  const theme = useTheme<MixTheme>();

  const [showDialog, setShowDialog] = useState({
    show: false,
    delete: false,
  });

  const handleDelete = useCallback(() => {
    setShowDialog({
      show: true,
      delete: true,
    });
  }, []);
  const handleDisable = useCallback(() => {
    setShowDialog({
      show: true,
      delete: false,
    });
  }, []);

  const handleClose = useCallback(() => {
    setShowDialog({
      show: false,
      delete: false,
    });
  }, []);

  const onClickConfirm = useCallback(() => {
    if (showDialog?.delete) {
      onClickDelete(item);
    } else {
      onClickDisable(item);
    }
    setShowDialog({
      show: false,
      delete: false,
    });
  }, [item, showDialog,onClickDelete,onClickDisable]);

  return (
    <TableRow key={item.company_uuid}>
      {Columns.map((column: GridColDef) => {
        return (
          <TableCell key={column.headerName}>
            <Typography color={theme.palette.text?.secondary}>
              {item[column.field as keyof Company]}
            </Typography>
          </TableCell>
        );
      })}
      <TableCell onClick={handleDisable}>
        {item.disabled ? (
          <EnableIcon color="warning" />
        ) : (
          <DisableIcon color="warning" />
        )}
      </TableCell>
      <TableCell onClick={handleDelete}>
        <DeleteIcon color="error" />
      </TableCell>
      <Dialog
        open={showDialog?.show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {showDialog?.delete
            ? "Delete !"
            : item?.disabled
            ? "Enable !"
            : "Disable !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showDialog?.delete
              ? "Are you sure you want to delete this company !"
              : item?.disabled
              ? "Are you sure you want to enable this company !"
              : "Are you sure you want to disable this company !"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="reset">
            Cancel
          </Button>
          <Button onClick={onClickConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};
export default CompanyItem;
