import ProductPromotionDetailsLayout, { ProductImage } from "../../layouts/productPromotionDetails";
import { DropdownOptions } from "../../components/Dropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserTypes } from "src/redux/userManagement/actions";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
import { useLocation, useNavigate } from "react-router-dom";

const ProductPromotionDetails = () => {
  const [storeId, setStoreId] = useState<string>("");

  const [images, setImages] = useState<ProductImage[]>([]);

  const stores: any[] = useSelector(
    (state: any) => state.userManagement.stores
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(!location.state?.id) {
      navigate("/promotions")
    }
  },[location.state, navigate])

  useEffect(() => {
    dispatch(getAllUserTypes());
  }, [dispatch]);

  const getImages = useCallback(async () => {
    try {
      const data = await getHttpClient(HTTPService.PRODUCT_PROMOTION_IMAGES, "get",null, {
        store_id:storeId,
        id: location.state.id
      });
      setImages(data.data.data);
    } catch (e) {}
  }, [storeId,location.state]);

  useEffect(() => {
    if (storeId) getImages();
  }, [storeId, getImages]);

  const storesList = useMemo<DropdownOptions[]>(() => {
    return stores.map((item: any) => {
      return {
        value: item?.user_uuid,
        label: `${item.username} - ${item.shop_id}`,
      } as DropdownOptions;
    });
  }, [stores]);



  useEffect(() => {
    if (storesList?.length > 0) {
      setStoreId(storesList[0].value)
    }
  }, [storesList]);

  return (
    <ProductPromotionDetailsLayout
      storesList={storesList}
      storeId={storeId}
      setStoreId={setStoreId}
      images={images}
    />
  );
};
export default ProductPromotionDetails;
