import ReactApexChart from "react-apexcharts";
import React, { useMemo } from "react";
import { ApexOptions } from "apexcharts";
import { OtherTrainingReport } from "src/interfaces/reports";
import { Box, Typography } from "@mui/material";
interface BarChartProps {
  title: string;
  values?: OtherTrainingReport;
  getColor?: (val: number) => void;
}
const OtherTrainingChart = ({ title, values }: BarChartProps) => {

  const data = useMemo(()=> {
    const arr:number[] =[];
    arr.push(values?.employeeCount ?? 0);
    values?.result.forEach((item) => {
      arr.push(item.count)
    })
    return arr;
  },[values])

  const series = useMemo<ApexOptions["series"]>(() => {
    return [
      {
        name: "series",
        data: data,
      },
    ] as ApexOptions["series"];
  }, [data]);

  const categories =  useMemo(()=> {
    const arr:string[] =[];
    arr.push("Employee Count");
    values?.result.forEach((item) => {
      arr.push(item.name)
    })
    return arr;
  },[values])

  const options = useMemo<ApexOptions>(() => {
    return {
      chart: {
        id: "basic-bar",
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      xaxis: {
        categories: categories,
      },
      tooltip:{
        y: {
          formatter: function (val) {
            const count = values?.employeeCount ?? 0;
            return ((val/parseInt(count.toString()))*100).toFixed(0) + "%"
          }
        }
      }
    } as ApexOptions;
  }, [values, categories]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        border: "2px solid #f1f1f1",
        padding: "16px",
        borderRadius: "4px",
        margin: "4px",
        height: "100%",
        maxHeight: '350px'
      }}
    >
      <Typography color="text.secondary" fontWeight={"700"} marginBottom={2}>
        {title}
      </Typography>
      <ReactApexChart
        type="bar"
        series={series}
        options={options}
        width={"100%"}
        height={"100%"}
      />
    </Box>
  );
};
export default OtherTrainingChart;
