import AdminDrawer from "../components/AdminDrawer";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import AdminDashbaordPage from "../pages/admin/dashboard";
import CompaniesPage from "../pages/admin/companies";
import CreateCompanyPage from "../pages/admin/companies/createCompany";
export const appAdminRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <>
          <AdminDrawer />
        </>
      }
      errorElement={<AdminDrawer />}
    >
      <Route index path="/" element={<AdminDashbaordPage />} />

      <Route path="/companies">
        <Route index element={<CompaniesPage />} />
        <Route path="/companies/create" element={<CreateCompanyPage />} />
      </Route>
    </Route>
  )
);
