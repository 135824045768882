import StoresListLayout from "../stores";
import React, { useCallback } from "react";
import TextInput from "../../components/TextInput";
import { Button, Typography, useTheme,Box } from "@mui/material";
import { MixTheme } from "@styles";
import { CreateManagerFormProps } from "src/interfaces/login";

interface CreateManagerProps {
  selectedStores: string[];
  onPressSelect: (id: string, value: boolean) => void;
  data: CreateManagerFormProps;
  errors: CreateManagerFormProps;
  setValues: (value: string, key: string) => void;
  onPressCreate: () => void;
  update:boolean;
}

const CreateAreaManagerLayout = ({
  selectedStores,
  onPressSelect,
  setValues,
  errors,
  data,
  onPressCreate,
  update
}: CreateManagerProps) => {
  const theme = useTheme<MixTheme>();
  const onChange = useCallback(
    (val: string, key?: string) => {
      if (key) {
        setValues(val, key);
      }
    },
    [setValues]
  );
  return (
    <Box>
      <Typography component={"h1"} fontWeight={"700"} fontSize={"28px"}>
        {update? "Edit Lead":"Create Lead"}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <TextInput
            label="Name"
            field="manager_name"
            sx={{
              margin: "0px 16px 16px 0px",
            }}
            value={data?.manager_name}
            error={errors?.manager_name}
            onChange={onChange}
          />
          <TextInput
            label="Area Name"
            field="shop_region"
            sx={{
              margin: "0px 16px 16px 0px",
            }}
            value={data?.shop_region}
            error={errors?.shop_region}
            onChange={onChange}
          />
          <TextInput
            label="Email"
            field="email"
            sx={{
              margin: "0px 16px 16px 0px",
            }}
            type="email"
            value={data?.email}
            error={errors?.email}
            onChange={onChange}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              alignSelf: "flex-end",
            }}
            onClick={onPressCreate}
          >
            {update?'Update Lead':`Create Lead`}
          </Button>
        </Box>
      </Box>
      <Typography
        fontWeight={"700"}
        color={theme.palette.text?.secondary}
        marginBottom={"8px"}
      >
        {"Select Users"}
      </Typography>
      <StoresListLayout
        select
        selectedStores={selectedStores}
        onPressSelect={onPressSelect}
      />
    </Box>
  );
};
export default CreateAreaManagerLayout;
