import React, {
  useMemo,
  useCallback,
} from "react";
import { Box, CircularProgress } from "@mui/material";
import { Viewer } from "@react-pdf-viewer/core";
import json from "./pdf.json";
import { searchPlugin } from "@react-pdf-viewer/search";
import {Helmet} from "react-helmet";

// Plugins
interface PdfParamas {
  isLoading: boolean;
  show: boolean;
}
const PdfPage = ({ isLoading, show }: PdfParamas) => {
  const searchPluginInstance = searchPlugin();
  const { ShowSearchPopoverButton } = searchPluginInstance;

  const base64ToArrayBuffer = useCallback((base64: string): Uint8Array => {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }, []);

  const buffer = useMemo(() => {
    return base64ToArrayBuffer(json.library);
  }, [base64ToArrayBuffer]);

  if (isLoading) {
    return (
      <Box>
        <CircularProgress color="success" />
      </Box>
    );
  }
  if (show) {
    return (
      <>
      <Helmet>

        <meta name="viewport" content="width=1280,initial-scale=1" />
      </Helmet>
      <div
        className="rpv-core__viewer"
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            padding: "4px",
          }}
        >
          <ShowSearchPopoverButton />
        </div>
          <div
            style={{
              height: '100vh',
            }}
          >
            <Viewer
              fileUrl={buffer}
              plugins={[searchPluginInstance]}
              enableSmoothScroll
            />
          </div>
      </div>
      </>
    );
  }
  return <div style={{}}></div>;
};
export default PdfPage;
