import React from "react";
import TextInput from "src/components/TextInput";
import {  Button, Typography, TextField, Grid } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";

import { DatePicker } from "@mui/x-date-pickers";
import * as Yup from "yup";
import moment from "moment";
export interface AddPromotionForm {
  title: string;
  promotionDate: string;
}
interface PromotionProps {
  onAddTrainings: (
    values: AddPromotionForm,
    formikHelpers: FormikHelpers<AddPromotionForm>
  ) => void;
}

const TrainingSchema = Yup.object().shape({
  title: Yup.string().min(3).required("Please enter name of product"),
  promotionDate: Yup.string().required("Please select promotion date"),
});
const AddTrainingLayout = ({ onAddTrainings }: PromotionProps) => {
  const { values, handleChange, handleSubmit, errors, setFieldValue } =
    useFormik({
      initialValues: {
        title: "",
        promotionDate: moment().format('YYYY-MM-DD').toString(),
      },
      onSubmit: onAddTrainings,
      validationSchema: TrainingSchema,
    });

  return (
    <>
      <Typography fontWeight={"700"} color={"black"} marginTop={"32px"}>
        {"Add New Promotion"}
      </Typography>
      <Grid container  spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <TextInput
            label="Product Name"
            field="name"
            sx={{
              width: "100%",
            }}
            value={values?.title}
            error={errors?.title}
            onChange={handleChange("title")}
          />
        </Grid>
        <Grid item >
          <DatePicker
            inputFormat="YYYY/MM/DD"
            value={values?.promotionDate}
            label={"Promotion date"}
            minDate={new Date()}
            onChange={(val) =>
              setFieldValue(
                "promotionDate",
                moment(val).format("YYYY-MM-DD").toString() ??
                  values?.promotionDate
              )
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            sx={{
              marginLeft: "8px",
              height: "35px",
            }}
            onClick={() => handleSubmit()}
          >
            {`Add`}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddTrainingLayout;
