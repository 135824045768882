import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Typography,
  useTheme,
} from "@mui/material";
import { MixTheme } from "@styles";
import React from "react";
import { QuestionCategories } from "src/interfaces/categories";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";

interface QuestionLayoutProps {
  categories: QuestionCategories[];
  expanded: number;
  setExpanded: (val: number) => void;
  onClickCategoryItem : (categoryId : number) => void;
}

const QuestionsLayout = ({
  categories,
  expanded,
  setExpanded,
  onClickCategoryItem
}: QuestionLayoutProps) => {
  const theme = useTheme<MixTheme>();

  const handleChange = (index: number) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? index : -1);
  };
  return (
    <Box>
      <Card
        sx={{
          padding: "16px",
          marginTop: "16px",
        }}
      >
        {categories.map((item, index) => {
          return (
            <Accordion
              expanded={expanded === index}
              key={index.toString()}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontWeight: "700" }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.categories.map((item) => {
                  return (
                    <Card
                      sx={{
                        padding: "16px",
                        margin: "4px",
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'space-between'
                      }}
                      key={item.id.toString()}
                      onClick={()=>onClickCategoryItem(item.id)}
                    >
                      <Typography
                        fontWeight={"700"}
                        color={theme.palette.text.secondary}
                      >
                        {item.title}
                      </Typography>
                      <EditIcon 
                        fontSize={'small'}
                        color={"action"}
                      />
                    </Card>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Card>
    </Box>
  );
};
export default QuestionsLayout;
