import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "src/redux/companies/actions";
import { useNavigate } from "react-router-dom";
import CompanyLayout from "../../../layouts/admin/companies/company.layout";
import { Company } from "src/interfaces/company";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const count = useSelector((state: any) => state.company.count);
  const companies = useSelector((state: any) => state.company.companies);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    dispatch(
      getCompanies({
        page,
        offset: limit,
      })
    );
  }, [page, limit, dispatch]);

  const onClickCreate = useCallback(() => {
    navigate("/companies/create", {
      state: {},
    });
  }, [navigate]);

  const onClickItem = useCallback(
    (item: any) => {
      navigate("/companies", {
        state: {},
      });
    },
    [navigate]
  );
  const onClickDelete = useCallback(
    async(item: Company) => {
      try {
        await getHttpClient(HTTPService.DELETE_COMPANY, "delete", {
          id: item.company_uuid,
        });

        dispatch(
          getCompanies({
            page,
            offset: limit,
          })
        );
      } catch (e) {
        console.log("ERROR", e);
      }
    },
    [dispatch,limit,page]
  );
  const onClickDisable = useCallback(
    async (item: Company) => {
      try {
        await getHttpClient(HTTPService.CHANGE_COMPANY_STATUS, "put", {
          id: item.company_uuid,
          status: !item.disabled,
        });

        dispatch(
          getCompanies({
            page,
            offset: limit,
          })
        );
      } catch (e) {
        console.log("ERROR", e);
      }
    },
    [dispatch,limit,page]
  );

  return (
    <CompanyLayout
      count={Number(count)}
      onClickItem={onClickItem}
      companies={companies}
      page={page}
      rowsPerPage={limit}
      onPageChange={setPage}
      onRowsPerPageChange={setLimit}
      onClickCreate={onClickCreate}
      onClickDelete={onClickDelete}
      onClickDisable={onClickDisable}
    />
  );
};

export default CompaniesPage;
