import { CompanyTypes } from "./types";

const inititalState = {
  count: 0,
  companies: [],
  page: 0,
};

const CompanyReducer = (state = inititalState, action: any) => {
  switch (action.type) {
    case CompanyTypes.GET_COMPANIES + "_SUCCESS":
      return {
        ...state,
        count: action.payload.data?.count,
        page: action.payload.data?.page,
        companies: action.payload.data?.companies,
      };
    default:
      return {
        ...state,
      };
  }
};
export default CompanyReducer;
