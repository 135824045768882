import React from "react";
import { TextField, SxProps, Theme,InputBaseComponentProps } from "@mui/material";
interface InputProps {
  sx?: SxProps<Theme>;
  value?: string;
  onChange?: (val: string, field?: string) => void;
  label?: string;
  field?: string;
  error?:string;
  inputProps?:InputBaseComponentProps;
  type?: React.HTMLInputTypeAttribute
}
const TextInput = ({ sx, label, value, onChange, field,error,inputProps,type }: InputProps) => {
  return (
    <TextField
      label={label}
      variant={"standard"}
      error={Boolean(error)}
      sx={{
        margin: "16px 0px 8px 0px",
        ...sx,
      }}
      value={value}
      onChange={(event) => {
        onChange?.(event.target.value, field);
      }}
      inputProps={inputProps}
      helperText={error}
      type={type}
    />
  );
};
export default TextInput;