import { createTheme } from '@mui/material/styles';
import { MixThemeOptions, MixTheme } from './ThemeOptions';

export const theme: MixTheme = createTheme({
    typography: {
        fontFamily: 'Sneak',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Sneak';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
            }
            @font-face {
                font-family: 'Sneak';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
              }
          `
        }
    },
    palette: {
        primary: {
            main: '#2C64E3'
        },
        secondary: {
            main: '#FFFFFF'
        },
        myAwesomeColor: 'red',
        text:{
            primary:'#2C64E3',
            secondary:'#000000'
        },
        background:{
            default:'#FAFAFC',
            paper:'#FFFFFF'
        },
    },
    font: {
        primary: 'Sneak',
        secondary: 'Sneak',
        tertiary: 'Sneak',
        sneak: 'Sneak',
    },
    global: {
        background: '#FAFAFC',
        primary:'#2C64E3',
        danger:'#e34646',
        mid:'#e39246',
        success:'#2a4f2a',
    },
} as MixThemeOptions);
