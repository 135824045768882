import { Box, Grid } from "@mui/material";
import React from "react";
import Dropdown, { DropdownOptions } from "../../components/Dropdown";

interface GalleryLayoutProps {
  storeId: string;
  setStoreId: (val: string) => void;
  storesList?: DropdownOptions[];
  images: ProductImage[];
}

export interface ProductImage {
  id: string;
  image: string;
  url: string;
}

const ProductPromotionDetailsLayout = ({
  storeId,
  images = [],
  setStoreId,
  storesList = [],
}: GalleryLayoutProps) => {
  return (
    <Box>
      
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box width={"200px"}>
        <Dropdown
          options={storesList}
          value={storeId}
          onChange={setStoreId}
        />
      </Box>
    </Box>
      <Grid container spacing={2}>
        {images.map((item) => {
          return <Grid item key={item.id.toString()} style={{width:'200px',margin:'16px'}}>
            <img src={item.url} height={'200px'} width={'200px'} alt={item.image}/>
          </Grid>;
        })}
      </Grid>
    </Box>
  );
};
export default ProductPromotionDetailsLayout;
