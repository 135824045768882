import * as React from "react";
import Box from "@mui/material/Box";
import { Card, Grid } from "@mui/material";
import DoughChart from "../../components/DoughChart";
import { DropdownOptions } from "../../components/Dropdown";
import { GraphDataProps } from "src/interfaces/dashboard";
import GraphFilter from "./components/GraphFilters";
import {
  DailyReports,
  OtherTrainingReport,
  SafetyReports,
  TrainingReports,
  WeeklyReports,
} from "src/interfaces/reports";
import GaugeChart from "../../components/GaugeChart";
import BarChart from "../../components/BarChart";
import TrainingChart from "../../components/TrainingChart";
import OtherTrainingChart from "../../components/OtherTrainingChart";

interface DashboardLayoutProps {
  dataSelector: GraphDataProps;
  storesList: DropdownOptions[];
  managerList: DropdownOptions[];
  onChangeDataSelector: (val: string) => void;
  onChangeDateSelector: (val: string) => void;
  onChangeDropdownValue: (key: string, val: string) => void;
  dailyReports?: DailyReports;
  weeklyReports?: WeeklyReports;
  safetyReports?: SafetyReports;
  trainingReports?: TrainingReports;
  otherTrainingReports?: OtherTrainingReport;
}

export default function DashboardLayout({
  dataSelector,
  onChangeDataSelector,
  storesList,
  managerList,
  onChangeDropdownValue,
  onChangeDateSelector,
  dailyReports,
  safetyReports,
  weeklyReports,
  trainingReports,
  otherTrainingReports
}: DashboardLayoutProps) {

  return (
    <Box>
      {/* <Toolbar /> */}
      <GraphFilter
        dataSelector={dataSelector}
        onChangeDataSelector={onChangeDataSelector}
        storesList={storesList}
        onChangeDropdownValue={onChangeDropdownValue}
        onChangeDateSelector={onChangeDateSelector}
        managerList={managerList}
      />
      <Card
        sx={{
          padding: "16px",
          marginTop: "16px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GaugeChart
              title="Critical Issues"
              values={dailyReports?.criticalIssues}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DoughChart
              title="Effeciency Report"
              values={dailyReports?.workFlowResults}
            />
          </Grid>
          
          <Grid item xs={12} md={6} >
            <TrainingChart title="Training Report" values={trainingReports} />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherTrainingChart title="Other Training Report" values={otherTrainingReports} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <BarChart
              title="Cleaning Issues"
              values={dailyReports?.cleaningResults}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Food safety Issues"
              values={safetyReports?.foodSafety}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Health and safety Issues"
              values={safetyReports?.healthAndSafety}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Company standards"
              values={safetyReports?.companyStandards}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Weekly cleaning Issues"
              values={weeklyReports?.weeklyCleaning}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              title="Weekly Health and safety Issues"
              values={weeklyReports?.weeklyHealthAndSafety}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
