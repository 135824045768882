import { Question } from "src/interfaces/questions";
import { QuestionTypes } from "./types";

const initialState = {
  questions: [],
};

const questionsReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case QuestionTypes.QUESTION_BY_CATEGORY + "_SUCCESS":
    case QuestionTypes.ADD_QUESTION + "_SUCCESS":
      return {
        ...state,
        questions: action.payload.data,
      };
      case QuestionTypes.EDIT_QUESTION + "_SUCCESS":
        const editedQuestions = state.questions.map((item:Question)=>{
          if(item.id === action.payload.data.id) {
            return action.payload.data;
          }
          return item
        })
        
        return {
          ...state,
          questions: editedQuestions
        }
        case QuestionTypes.DELETE_QUESTION + "_SUCCESS":
          const questions = state.questions.filter((item:Question)=> item.id !== Number(action.payload.data.id))
          return {
            ...state,
            questions: [...questions]
          }
    default:
      return {
        ...state,
      };
  }
};
export default questionsReducer;