import {  useSelector } from "react-redux";
import ProductPromotionsLayout from "../../layouts/productPromotions";
import React, { useCallback, useEffect, useState } from "react";
import { getHttpClient } from "src/services/rest-api";
import { HTTPService } from "src/services/httpservice";
import { FormikHelpers } from "formik";
import { AddPromotionForm } from "../../layouts/productPromotions/AddPromotion";
import { ProductPromotionType } from "src/interfaces/ProductPromotion";
import moment from "moment";

const ProductPromotions = () => {
  const user = useSelector((state: any) => state.user.user);

  const [promotions, setPromotions] = useState<ProductPromotionType[]>([]);

  const getPromotions = useCallback(async () => {
    try {
      const date =  moment().format("YYYY-MM-DD").toString()

      const result = await getHttpClient(HTTPService.PRODUCT_PROMOTION,'get',null,{
        date
      });
      setPromotions(result.data.data);
    } catch (e) {
      // TODO e
    }
  }, []);

  // useEffect(()=>{
  //   dispatch(getUserData());
  // },[])

  useEffect(() => {
      getPromotions();
  }, [getPromotions]);

  const onAddTrainings = useCallback(
    async (
      values: AddPromotionForm,
      formikHelpers: FormikHelpers<AddPromotionForm>
    ) => {
      const date =  moment().format("YYYY-MM-DD").toString()
      try {
        const result = await getHttpClient(
          HTTPService.PRODUCT_PROMOTION,
          "post",
          {
            ...values,
            date: date
          }
        );
        formikHelpers.resetForm()
        setPromotions(result.data.data);
      } catch (e) {
        // TODO e
      }
    },
    []
  );


  return (
    <ProductPromotionsLayout
      role={user.role}
      onAddTrainings={onAddTrainings}
      trainings={promotions}
    />
  );
};
export default ProductPromotions;
