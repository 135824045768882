import PasswordInput from "src/components/PasswordInput";
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { MixTheme } from "@styles";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

export interface SetPasswordType {
  password: string;
  confirmPassword: string;
}

interface SetPasswordProps {
  onSetPassword: (values: SetPasswordType) => void;
}

const Schema = Yup.object().shape({
  password: Yup
    .string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
    ),

  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords must match")
});

const SetPasswordLayout = ({ onSetPassword }: SetPasswordProps) => {
  const theme = useTheme<MixTheme>();

  const { values, handleChange, errors, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    } as SetPasswordType,
    onSubmit: onSetPassword,
    validationSchema: Schema,
    validateOnChange: false,
  });

  return (
    <Box
      sx={{
        backgroundColor: theme.palette?.background?.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth={"sm"}>
        <Card
          sx={{
            width: "100%",
            backgroundColor: "#FFF",
            padding: "32px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            fontWeight={"700"}
            fontSize={"20px"}
            fontFamily={theme.font?.tertiary}
            color={theme.palette?.text?.primary}
          >
            {'Set Password'}
          </Typography>
          <PasswordInput
            label={"Password"}
            value={values?.password}
            error={errors?.password}
            field={"username"}
            onChange={handleChange("password")}
          />
          <PasswordInput
            label={"Confirm Password"}
            value={values?.confirmPassword}
            field={"password"}
            error={errors?.confirmPassword}
            onChange={handleChange("confirmPassword")}
          />
          <Button
            variant="contained"
            sx={{
              margin: "16px 0px 16px 0px",
            }}
            onClick={() => handleSubmit()}
          >
            {'Set Password'}
          </Button>
        </Card>
      </Container>

      {/* <TyfhuSnack snackbar={snackbar} handleClose={handleClose} /> */}
    </Box>
  );
};
export default SetPasswordLayout;
