import {HTTPService} from '../../services/httpservice';
import {UserTypes} from './types';

export const adminLogin = data => {
  return {
    type: UserTypes.LOGIN,
    request: {
      path: HTTPService.LOGIN,
      method: 'POST',
      data,
    },
  };
};

export const getUserData = () => {
  return {
    type: UserTypes.GET_USER,
    request: {
      path: HTTPService.GET_USER ,
      method: 'GET',
    },
  };
};

export const setPassword = (id, data) => {
  return {
    type: UserTypes.GET_USER,
    request: {
      path: HTTPService.SET_PASSWORD + id,
      method: 'PUT',
      data,
    },
  };
};
export const updateEmail = (id, data) => {
  return {
    type: UserTypes.GET_USER,
    request: {
      path: HTTPService.UPDATE_EMAIL + id,
      method: 'PUT',
      data,
    },
  };
};
export const updateLocation = (id, data) => {
  return {
    type: UserTypes.GET_USER,
    request: {
      path: HTTPService.UPDATE_LOCATION + id,
      method: 'PUT',
      data,
    },
  };
};

export const updateShopDetails = (id,data)=> {
  return {
    type: UserTypes.GET_USER,
    request: {
      path: HTTPService.UPDATE_SHOP + id,
      method: 'PUT',
      data,
    },
  };
}
