import React, { useState, useCallback } from "react";
import { Box, Card, Typography, Button, Checkbox } from "@mui/material";
import { useLocation } from "react-router-dom";
import TextInput from "../../../components/TextInput";
import Dropdown, { DropdownOptions } from "../../../components/Dropdown";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addQuestion } from "src/redux/questions/actions";

const QuestionTypes: DropdownOptions[] = [
  {
    label: "Health and Safety",
    value: "HS",
  },
  {
    label: "Food and Safety",
    value: "FS",
  },
  {
    label: "Company standards",
    value: "CS",
  },
];

interface NewQuestion {
  question: string;
  crictical: boolean;
  category?: string;
}

const schema = yup.object().shape({
  question: yup.string().required("Please enter question"),
  category: yup.string().required("Please select a category"),
});

const AddQuestion = () => {
  const location = useLocation();
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { values, resetForm, errors, handleChange, isValid } = useFormik({
    initialValues: {
      question: "",
      crictical: false,
      category: "",
    } as NewQuestion,
    validationSchema: schema,
    onSubmit: () => undefined,
    validateOnChange: true,
  });

  const onClickAddQuestion = useCallback(() => {
    setIsAdding(!isAdding);
    resetForm();
  }, [isAdding,resetForm]);
  const onClickSave = useCallback(() => {
    if (isValid) {
      const payload = {
        questionType: values?.category,
        question: values.question,
        critical: values.crictical,
        category_id: location?.state?.categoryId,
      };
      dispatch(addQuestion(payload));
      setIsAdding(false);
      resetForm();
    }
  }, [isValid, values,resetForm,dispatch,location]);

  if (!isAdding) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={onClickAddQuestion}
          sx={{
            height: "40px",
          }}
        >
          {"Add New Question"}
        </Button>
      </Box>
    );
  }

  return (
    <Card
      sx={{
        padding: "16px",
        margin: "8px 8px 20px 8px",
      }}
    >
      <Typography fontWeight={"700"} fontSize={"16px"}>
        Add Question
      </Typography>
      <Box width={"100%"}>
        <TextInput
          value={values?.question}
          error={errors?.question}
          label="Question"
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
          onChange={handleChange("question")}
        />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Dropdown
              options={QuestionTypes}
              title={"Question Type"}
              error={errors?.category}
              value={values?.category}
              fullWidth={false}
              onChange={handleChange("category")}
              sx={{
                width: "200px",
              }}
            />
            <Typography
              sx={{
                margin: "0px 4px 0px 16px",
                color: "text.secondary",
              }}
            >
              {"Critical"}
            </Typography>
            <Checkbox
              checked={values.crictical}
              onChange={handleChange("crictical")}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{
              marginLeft: "16px",
            }}
          >
            <Button
              variant="contained"
              onClick={onClickAddQuestion}
              sx={{
                width: "100px",
                height: "40px",
                marginRight: "16px",
                background: "red",
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="contained"
              onClick={onClickSave}
              sx={{
                width: "100px",
                height: "40px",
              }}
            >
              {"Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
export default AddQuestion;
