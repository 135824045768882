import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

interface Types {
  pz: string;
  rs: string;
  cf: string;
  pb: string;
  bky: string;
  cky: string;
  sh: string;
}

const RestaurantType: Types = {
  pz: "Pizza",
  rs: "Restaurant",
  cf: "Coffee",
  pb: "Pub",
  bky: "Bakery",
  cky: "Chicken",
  sh: "Sushi",
};

const CompanyDetails = () => {
  const user = useSelector((state: any) => state.user.user);

  return (
    <Box
      sx={{
        marginBottom: "32px",
      }}
    >
        
      <Card
        sx={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
      <Typography color={"black"} variant="h4" fontWeight={"600"}>
        Company Details
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography color={"black"}>Name</Typography>
          <Typography>{user?.company_name}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography color={"black"}>Type</Typography>
          <Typography>
            {RestaurantType[user?.restaurant_type as keyof Types]}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography color={"black"}>Country</Typography>
          <Typography>{user?.company_country}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography color={"black"}>Branches</Typography>
          <Typography>{user?.no_of_branches}</Typography>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
      </Card>
    </Box>
  );
};
export default CompanyDetails;
