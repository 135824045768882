export const BaseURL = process.env.REACT_APP_API_BASE_URL;

export const HTTPService = {
  LOGIN: BaseURL + "/admin/login",
  GET_USER: BaseURL + "/user/",
  GET_ALL_USER_TYPES: BaseURL + "/admin/users",
  CREATE_AREA_MANAGER: BaseURL + "/admin/create/areamanager",
  UPDATE_AREA_MANAGER: BaseURL + "/admin/update/areamanager",
  DELETE_AREA_MANAGER: BaseURL + "/admin/delete/areamanager",
  DAILY: BaseURL + "/reports/daily",
  WEEKLY: BaseURL + "/reports/weekly",
  SAFETY: BaseURL + "/reports/safety",
  REPORTS_TRAINING: BaseURL + "/reports/training",
  REPORTS_OTHER_TRAINING: BaseURL + "/reports/other-training",
  QUESTION_BY_CATEGORY: BaseURL + "/questions/questionsByCategory",
  EDIT_QUESTION: BaseURL + "/questions/edit",
  ADD_QUESTION: BaseURL + "/questions/add",
  GET_COMPANIES: BaseURL + "/appadmin/companies",
  ADD_COMPANY: BaseURL + "/appadmin/company/create",
  LIBRARY: BaseURL + "/library/verify",
  SET_PASSWORD: BaseURL + "/user/set-password/",
  CHANGE_COMPANY_STATUS: BaseURL + "/appadmin/company/status",
  DELETE_COMPANY: BaseURL + "/appadmin/company",
  UPDATE_INTERNAL_TRAINING: BaseURL + "/admin/internalTraining",
  OTHER_TRAINING: BaseURL + "/externalTraining",
  PRODUCT_PROMOTION: BaseURL + "/promotions",
  PRODUCT_PROMOTION_IMAGES: BaseURL + "/promotions/images/admin",
  PDF_GENERATE: BaseURL + "/reports/pdf/generate",
  GALLERY: BaseURL + "/gallery/admin/",
  GET_ACCIDENT : BaseURL+'/accidents/',
  ACCIDENT_REPORT : BaseURL+'/accidents/report',
  SEND_NOTIFICATION : BaseURL+'/notifications/',
};
